.FinalPage {
    background-color: #FDCC00;
    padding: 2.5em;
    border-radius: 0.625em;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #000000;
    text-align: start;
}

.FinalPage #lang {
    width: 57%;
}

.FinalPage .input-box select option {
    font-size: 1em;
}

.full-name {
    width: 25%;
}

.score {
    display: flex;
    justify-content: space-between;
}

h1,
h2 {
    text-align: center;
    margin-top: 1.25em;
    font-weight: 600;
}

p {
    font-size: 1.2em;
    margin: 0.625em 0;
}
