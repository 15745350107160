@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
}

body {
  width: 100%;
}

html {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96%;
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="range"] {
  width: 100%;
  margin-top: 0.625em;
  accent-color: black;
}
input[type="text"],[type="password"],[type="number"] {
  background: white;
  width: 95%;
  padding: 0.625em 0 0.625em 0.625em;
  margin-top: 0.625em;
  border: 0.1em solid #000000;
  border-radius: 0.3125em;
  font-size: 1.5em;
}

select {
  font-size: 1.5em;
  color: #444;
  line-height: 1.3;
  padding: 0.625em 0.625em 0.625em 0.625em;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.625em;
  border: 0.1em solid #000000;
  border-radius: 0.3125em;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtZG93bi1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPjxwYXRoIGQ9Ik03LjI0NyAxMS4xNGwtNC43OTYtNS40ODFDMi4xNjggNS4yNjQgMi41MTEgNC41IDMuMDcgNC41aDkuODU4Yy41NiAwIC45MDIuNzY0LjYxOSAxLjE1OWwtNC43OTYgNS40ODFhMSAxIDAgMCAxLTEuNTA0IDB6Ii8+PC9zdmc+');
  background-repeat: no-repeat;
  background-position: right 0.5em top 50%;
  background-size: 1em;
  cursor: pointer;
}
label {
  margin: .8em 0;
  width: 20%;
  display: block;
  background-color: white;
  text-align: center;
  font-size: 1.125em;
  border-radius: 1.25em;
}

button {
  display: block;
  width: 100%;
  padding: 0.9375em;
  margin-top: 1.25em;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 0.3125em;
  cursor: pointer;
  font-size: 1.3em;
  transition: all .2s ease-out;
}

button:hover {
  background-color: rgb(68, 68, 68)
}

button:disabled {
  background-color: #ccc;
}

.App {
  max-width: 25em;
  width: 100%;
  margin: 0 auto;
}
