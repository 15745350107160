.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(0.3125em);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 1.25em;
    border-radius: 0.625em;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.1);
    width: 18.75em;
    text-align: center;
    position: relative;
}

.login-input {
    border: 0.0625em solid #ccc;
    font-size: 1.3em;
}

.close-button {
    width: 0;
    height: 0;
    position: absolute;
    top: 1.7em;
    left: 12.7em;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #000000;
    cursor: pointer;
    transition: 0.3s;
    padding: 0;
    margin: 0;
}

.close-button:hover {
    color: #FDCC00;
}