.ImageContainer {
  position: relative;
  width: 400px;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.overlay-button {
  position: absolute;
  bottom: 20px;
  padding: 10px 20px;
  background-color: rgba(0, 123, 255, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.overlay-button:hover {
  background-color: rgba(0, 123, 255, 1);
}
